import * as React from "react";
import {Pagination} from "../../../../@types/pagination";
import {ListElement} from "../../../../components/list/items/list-element";
import {Item} from "../../../../components/list/list-props.common";
import {FlatListRenderItemParams, SelectFlatList} from "../../../../components/list/select-flatlist";
import {HeaderMenu} from "../../../../components/menus/header";
import {ModalWrapper} from "../../../../components/views/modal-wrapper";
import {filterTruthy} from "../../../../utils/arrays";
import {useTranslation} from "../../../../utils/hooks/use-translation";
import {Locales} from "../../../../utils/locales/locales";
import {TranslationFunc} from "../../../../utils/locales/translations";
import {SharedScreenProps} from "../../../../utils/navigation/paramLists/root-param-list";
import {paginate} from "../../../../utils/pagination";
import {textContains} from "../../../../utils/searches";
import {forceBack} from "../../../navigation";

export const origins = [
	"AD",
	"AE",
	"AF",
	"AG",
	"AI",
	"AL",
	"AM",
	"AN",
	"AO",
	"AQ",
	"AR",
	"AS",
	"AT",
	"AU",
	"AW",
	"AX",
	"AZ",
	"BA",
	"BB",
	"BD",
	"BE",
	"BF",
	"BG",
	"BH",
	"BI",
	"BJ",
	"BL",
	"BM",
	"BN",
	"BO",
	"BR",
	"BS",
	"BT",
	"BV",
	"BW",
	"BY",
	"BZ",
	"CA",
	"CC",
	"CD",
	"CE",
	"CF",
	"CG",
	"CH",
	"CI",
	"CK",
	"CL",
	"CM",
	"CN",
	"CO",
	"CR",
	"CS",
	"CU",
	"CV",
	"CW",
	"CY",
	"CZ",
	"DE",
	"DJ",
	"DK",
	"DM",
	"DO",
	"DZ",
	"EC",
	"EE",
	"EG",
	"EH",
	"ER",
	"ES",
	"ET",
	"FI",
	"FJ",
	"FK",
	"FM",
	"FO",
	"FR",
	"GA",
	"GB",
	"GD",
	"GE",
	"GF",
	"GG",
	"GH",
	"GI",
	"GL",
	"GM",
	"GN",
	"GP",
	"GQ",
	"GR",
	"GT",
	"GU",
	"GW",
	"GY",
	"HK",
	"HM",
	"HN",
	"HR",
	"HT",
	"HU",
	"ID",
	"IE",
	"IL",
	"IM",
	"IN",
	"IQ",
	"IR",
	"IS",
	"IT",
	"JE",
	"JM",
	"JO",
	"JP",
	"KE",
	"KG",
	"KH",
	"KM",
	"KN",
	"KR",
	"KW",
	"KY",
	"KZ",
	"LA",
	"LB",
	"LC",
	"LI",
	"LK",
	"LR",
	"LS",
	"LT",
	"LU",
	"LV",
	"LY",
	"MA",
	"MC",
	"MD",
	"ME",
	"MF",
	"MG",
	"MH",
	"MK",
	"ML",
	"MM",
	"MN",
	"MO",
	"MP",
	"MQ",
	"MR",
	"MS",
	"MT",
	"MU",
	"MV",
	"MW",
	"MX",
	"MY",
	"MZ",
	"NA",
	"NC",
	"NE",
	"NF",
	"NG",
	"NI",
	"NL",
	"NO",
	"NP",
	"NR",
	"NU",
	"NZ",
	"OM",
	"PA",
	"PE",
	"PF",
	"PG",
	"PH",
	"PK",
	"PL",
	"PM",
	"PN",
	"PR",
	"PS",
	"PT",
	"PW",
	"PY",
	"QA",
	"RE",
	"RO",
	"RS",
	"RU",
	"RW",
	"SA",
	"SB",
	"SC",
	"SD",
	"SE",
	"SG",
	"SH",
	"SI",
	"SJ",
	"SK",
	"SL",
	"SM",
	"SN",
	"SO",
	"SR",
	"SS",
	"ST",
	"SV",
	"SX",
	"SY",
	"SZ",
	"TB",
	"TC",
	"TD",
	"TG",
	"TH",
	"TJ",
	"TK",
	"TL",
	"TM",
	"TN",
	"TO",
	"TR",
	"TT",
	"TV",
	"TW",
	"TZ",
	"UA",
	"UG",
	"US",
	"UY",
	"UZ",
	"VA",
	"VC",
	"VE",
	"VG",
	"VI",
	"VN",
	"VU",
	"WF",
	"WS",
	"XK",
	"YE",
	"YT",
	"ZA",
	"ZM",
	"ZW",
] as const;
export const notRelevantOrigin = "notRelevant";
export type Origin = typeof origins[number];
export type OriginsOrNotRelevant = Origin[] | typeof notRelevantOrigin;
export type OriginListItem = Item<"id", {displayed: string; id: Origin | typeof notRelevantOrigin}>;

const sort = (a: OriginListItem, b: OriginListItem): number => Locales.compare(a.displayed, b.displayed);

export const getOriginList = (
	t: TranslationFunc, ct: TranslationFunc, includedOrigins?: readonly Origin[] | null, includeNotRelevant?: boolean,
	selectionOrBase?: OriginsOrNotRelevant, search?: string,
): OriginListItem[] => {
	const list: OriginListItem[] = (includedOrigins || (includeNotRelevant ? [] as Origin[] : origins))
		.map((origin: Origin) => ({
			displayed: t(`origins:${origin}`),
			id: origin,
			selected: selectionOrBase?.includes(origin),
		}))
		.filter(origin => search ? textContains(search, origin.displayed, true) : true)
		.sort(sort);

	if (includeNotRelevant) {
		list.unshift({displayed: ct("common:notRelevant"), id: notRelevantOrigin});
	}
	return list;
};

const SelectOrigin = ({navigation, route}: SharedScreenProps<"SelectOriginModal">): JSX.Element => {
	const {selectionOrBase, onSelect: onSelectParam, includedOrigins, includeNotRelevant} = route.params ?? {};
	const {ct, t} = useTranslation();

	const onSelect = React.useCallback(
		(origin: OriginsOrNotRelevant) => () => {
			onSelectParam?.(origin);
			navigation.dispatch(forceBack);
		},
		[navigation, onSelectParam],
	);

	const renderItem = React.useCallback(
		({info: {item}, buttons}: FlatListRenderItemParams<"id", OriginListItem>) => (
			<ListElement
				onPress={selectionOrBase
					? item.id === notRelevantOrigin
						? onSelect(notRelevantOrigin)
						: filterTruthy(buttons).find(button => button.key === "select")?.onPress
					: onSelect(item.id === notRelevantOrigin ? notRelevantOrigin : [item.id])
				}
				buttons={buttons}
			>
				{item.displayed}
			</ListElement>
		),
		[onSelect, selectionOrBase],
	);

	const getRequest = React.useCallback(
		(pagination: Pagination) => Promise.resolve(
			paginate(getOriginList(t, ct, includedOrigins, includeNotRelevant, selectionOrBase), pagination)),
		[ct, includeNotRelevant, includedOrigins, selectionOrBase, t],
	);

	const searchRequest = React.useCallback(
		(pagination: Pagination, search: string) => Promise.resolve(
			paginate(getOriginList(t, ct, includedOrigins, includeNotRelevant, selectionOrBase, search), pagination)),
		[ct, includeNotRelevant, includedOrigins, selectionOrBase, t],
	);

	const onSearch = React.useMemo(
		() => ({
			request: searchRequest,
		}),
		[searchRequest],
	);

	return (
		<>
			<HeaderMenu center={t("forms:inputs.selectOrigin")}/>
			<SelectFlatList
				getRequest={getRequest}
				onSearch={onSearch}
				renderItem={renderItem}
				{...selectionOrBase && onSelectParam && {
					onPressSelect: {
						onPress: (items: OriginListItem[] | string) => {
							if (Array.isArray(items)) {
								onSelectParam(items.filter(i => i.id !== notRelevantOrigin).map(i => i.id as Origin));
							}
							navigation.dispatch(forceBack);
						},
					},
				}}
				itemTranslationKey="forms:inputs.selectOrigin"
				idKey="id"
			/>
		</>
	);
};

export const SelectOriginModal = (props: SharedScreenProps<"SelectOriginModal">): JSX.Element => (
	<ModalWrapper fullHeight>
		<SelectOrigin {...props} />
	</ModalWrapper>
);
