import {Session} from "../../../@types/activities/session";
import {InterpreterFilters} from "../../../@types/identities/filters";
import {Interpreter} from "../../../@types/identities/interpreter";
import {computeEndTime} from "../../../utils/date-time/helpers";
import {api} from "../../../utils/network/api";
import {GetDetailedSessionResponse} from "../../@types/session";
import {UserSession} from "../../@types/user";
import {
	transformLanguageISOCodeFrontToBack, transformSessionContextFrontToBack,
	transformSessionTypeFrontToBack,
} from "../../utils/transform";
import {transformGenderFrontToBack} from "../../utils/transform-common";
import {transformCreateSessionBackToFront} from "../../utils/transform-session";

export type UserCreateSessionRequests = {
	POST: {
		body: UserSession,
	},
};
export const createSession = async (
	session: Session,
	initialExcludedInterpreterIds: Interpreter["accountId"][],
	filters: InterpreterFilters,
): Promise<Session> => {
	const {
		start, end, language, toLanguage, internalComment, providerComment, communication, requester, context,
		immediate, subject, providedService, receiver, medicalProfessional, otherProfessional, paymentAuthority,
		category, activityId, duration, providers, excludedInterpreters, toccoBillingInformation, toccoPeopleNumber,
		billingAddress,
	} = session;
	const {gender, origins, interpretersGroups, qualifications} = filters;
	const bhaashaInterpreterGroups: number[] = [];
	if (interpretersGroups) {
		interpretersGroups.forEach(group => bhaashaInterpreterGroups.push(Number.parseInt(group.id)));
	} else {
		throw new Error("No Interpreter Group");
	}
	const requestBody: UserSession = {
		bhaashaInterpreterGroups,
		bookingDate: start?.getTime() || Date.now(),
		bookingType: transformSessionContextFrontToBack(context),
		title: subject,
		endTime: (end || computeEndTime(new Date(), duration)).getTime(),
		fromLanguage: {isoCode: transformLanguageISOCodeFrontToBack(language)},
		internalComments: internalComment,
		interpreterList: providers.map(i => i.identityId),
		selectedInterpretersList: providers.map(i => ({
			id: Number.parseInt(i.identityId, 10),
			status: "Available", // todo
		})),
		unSelectedInterpretersList: [
			...initialExcludedInterpreterIds,
			...excludedInterpreters.map(e => e.identityId),
		].map(i => ({
			id: Number.parseInt(i, 10),
			status: "UnAvailable", // todo
		})),
		owner: {
			firstName: requester.fullName.firstName,
			id: Number.parseInt(requester.identityId, 10),
			lastName: requester.fullName.lastName,
			telephone: requester.phone,
			email: requester.email,
		},
		purpose: providerComment || "",
		sessionCategory: immediate ? "IMMEDIATE" : "SCHEDULED",
		sessionType: transformSessionTypeFrontToBack(communication.type, providedService.type),
		startTime: start?.getTime() || Date.now(),
		toccoNbPersons: toccoPeopleNumber,
		toccoBillingInformation,
		toLanguage: {isoCode: transformLanguageISOCodeFrontToBack(toLanguage)},
		sessionFilters: {
			qualification: qualifications ? qualifications.map(i => i.value).toString() : "",
			gender: transformGenderFrontToBack(gender),
			origins: origins === null ? [] : origins || "",
		},
		isCancelledByInterpreter: false,
		isSessionRescheduled: context.type === "reschedule",
		id: context.type === "followUp" ? Number.parseInt(activityId) : undefined,
		userBillingAddressId: billingAddress?.id ? Number(billingAddress.id) : undefined,
	};
	if (context.type === "reschedule") {
		requestBody.reasonToReschedule = context.rescheduleData.reason;
		requestBody.rescheduledSessionId = activityId;
		requestBody.isCancelledByInterpreter = context.rescheduleData.canceledByProvider;
	}
	if (paymentAuthority) {
		requestBody.paymentAuthorityOptionId = Number.parseInt(paymentAuthority.id);
		requestBody.paymentAuthorityOption = {
			id: Number.parseInt(paymentAuthority.id),
			paymentOption: paymentAuthority.name,
		};
	}
	if (category) {
		requestBody.categoryOptionId = Number.parseInt(category.id);
		requestBody.categoryOption = {
			id: Number.parseInt(category.id),
			categoryOption: category.name,
		};
	}
	if (communication.type === "inPerson") {
		requestBody.latitude = communication.place.geocode.lat;
		requestBody.longitude = communication.place.geocode.lng;
		requestBody.formattedAddress = communication.place.address;
		requestBody.locationDetails = communication.placeInfos ?? "";
		requestBody.selectedInterpretersList = providers.map(i => ({
			id: Number.parseInt(i.identityId, 10),
			wave: i.wave === "first" ? "ONE" : "TWO",
			status: "Available", // todo
		}));
		if (providedService.type === "mediation") {
			const {reason, goal, expectation, involvedCorporations, involvedPeople} = providedService;
			requestBody.goalOfConversation = goal;
			requestBody.expectationOfMediation = expectation;
			requestBody.reasonForConversation = reason;
			requestBody.otherInvolvedPositions = JSON.stringify(involvedCorporations);
			requestBody.personInvolvedInConversation = JSON.stringify(involvedPeople);
		}
	}
	if (receiver) {
		requestBody.patient = {id: Number.parseInt(receiver.identityId, 10)};
	}
	if (medicalProfessional) {
		requestBody.user = {id: Number.parseInt(medicalProfessional.identityId, 10)};
	}
	if (otherProfessional) {
		requestBody.otherUser = {id: Number.parseInt(otherProfessional.identityId, 10)};
	}
	const response = await api(
		"userCreateSession",
		"POST",
		{
			body: requestBody,
		},
	) as GetDetailedSessionResponse;

	return response.isSuccess
		? Promise.resolve(transformCreateSessionBackToFront(response.data))
		: Promise.reject(new Error(response.errorId || response.message));
};
