import {useNavigation} from "@react-navigation/native";
import * as React from "react";
import {ActivityIndicator, StyleSheet, View} from "react-native";
import {Mediation} from "../../../../@types/activities/mandate";
import {SessionCategory} from "../../../../@types/activities/session-category";
import {BillingAddress} from "../../../../@types/billing-address";
import {Corporation} from "../../../../@types/identities/corporation";
import {WaveInterpreterProvider, waveTypes} from "../../../../@types/identities/interpreter";
import {PersonIdentityPreview, PersonListedFields, PersonPreview} from "../../../../@types/identities/person";
import {MedicalProfessionalPreview, OtherProfessionalPreview} from "../../../../@types/identities/professional";
import {Receiver, ReceiverPreview} from "../../../../@types/identities/receiver";
import {Pagination} from "../../../../@types/pagination";
import {PaymentAuthority} from "../../../../@types/payment-authority";
import {
	IdentityEditableFieldsSetting,
	PersonListedFieldsSetting,
	SessionCreationSetting,
	ToccoSetting,
} from "../../../../@types/settings";
import {Button} from "../../../../components/buttons/button";
import {Icon} from "../../../../components/icons";
import {Form, FormInput} from "../../../../components/inputs/form";
import {DropdownSelectListProps} from "../../../../components/inputs/list-inputs/dropdown-select-input";
import {
	TextInputDropdownSuggestionItem,
} from "../../../../components/inputs/list-inputs/text-input-dropdown-suggestion";
import {HeaderMenu} from "../../../../components/menus/header";
import {ScrollView} from "../../../../components/scrollables/scroll-view";
import {Separator} from "../../../../components/separator";
import {Spacer} from "../../../../components/spacer";
import {StatusBar} from "../../../../components/status-bar";
import {Text} from "../../../../components/texts/text";
import {KeyboardAvoidingView} from "../../../../components/views/keyboard-avoiding-view";
import {ActivitiesContext} from "../../../../contexts/activities";
import {AuthentifiedContext} from "../../../../contexts/authentified";
import {ResponsiveContext} from "../../../../contexts/responsive";
import {SessionCreationContext} from "../../../../contexts/session-creation";
import {
	addBillingAddress,
	getBillingAddresses as getBillingAddressesRequest,
} from "../../../../requests/clients/activities/billing-addresses";
import {
	addSessionCategory,
	getSessionCategories as getSessionCategoriesRequest,
} from "../../../../requests/clients/activities/categories";
import {
	addPaymentAuthority,
	getPaymentAuthorities as getPaymentAuthoritiesRequest,
} from "../../../../requests/clients/activities/payment-authorities";
import {getInterpretersImmediate} from "../../../../requests/clients/new-session/session-slots";
import {
	getSubjectSuggestions as getSubjectSuggestionsRequest,
	searchSubjectSuggestions as searchSubjectSuggestionsRequest,
} from "../../../../requests/clients/new-session/subject-suggestion";
import {formatBillingAddress} from "../../../../utils/billing-addresses";
import {CONTAINERS, DEFAULT_SPACING, EXTRA_LARGE_SPACING, SMALL_SPACING} from "../../../../utils/constants";
import {formatShortestDate, formatShortTimeRange} from "../../../../utils/date-time/format";
import {useToccoPeopleNumberInput} from "../../../../utils/hooks/use-tocco-people-number-input";
import {useTranslation} from "../../../../utils/hooks/use-translation";
import {corporationIdentity, getDescriptionLabel, personIdentity, receiverIdentity} from "../../../../utils/identities";
import {Rules} from "../../../../utils/inputs/rules/rules";
import {Log} from "../../../../utils/logs/logs";
import {ClientStackParamList} from "../../../../utils/navigation/paramLists/root-param-list";
import {randomString} from "../../../../utils/randoms";
import {getCommunicationTypeIcon} from "../../../../utils/sessions/communications";
import {BLUE, PRIMARY, PRIMARY_2, SUBTLE, SUBTLE_4, WHITE_OVERLAY} from "../../../../utils/styles/colors";
import {TOCCO_DEFAULT_PERSON_NUMBER} from "../../../../utils/tocco";
import {selectPersonConfig} from "../../common/modals/select-person";
import {LOADER_SIZE} from "./schedule-step";

const getLabelPaymentAuthorities = (paymentAuthority: PaymentAuthority): string => paymentAuthority.name;
const getLabelBillingAddress = (billingAddress: BillingAddress): string => formatBillingAddress(billingAddress);
const getLabelSessionCategory = (sessionCategory: SessionCategory): string => sessionCategory.name;

export const DetailsStep = (
	{onBack, onValidate, onError}: {
		onBack: () => void;
		onError: () => void;
		onValidate: () => void;
	},
): JSX.Element => {
	const {ct, t} = useTranslation();
	const navigation = useNavigation();
	const {columns, mobileDisplay} = React.useContext(ResponsiveContext);
	const {accountId, settings: {getSetting}} = React.useContext(AuthentifiedContext);
	const {
		value: {
			detailsStep: {
				billingAddress,
				interpreterComment,
				internalComment,
				requester,
				subject,
				toXInterpreter,
				expectation,
				goal,
				involvedCorporations,
				involvedPeople,
				reason,
				medicalProfessional,
				otherProfessional,
				paymentAuthority,
				receiver,
				sessionCategory,
			},
		},
	} = getSetting<SessionCreationSetting>("session/creation", "organization/all") ?? {value: {detailsStep: {}}};
	const {value: receiverListedFields} = getSetting<PersonListedFieldsSetting<Receiver>>(
		"person/listed-fields",
		"requester/receiver",
	) ?? {
		value: new Set([
			"firstName",
			"lastName",
			"gender",
			"evamNumber",
			"ippNumber",
			"description",
		]) as PersonListedFields<Receiver>,
	};
	const {value: receiverEditableFields} = getSetting<IdentityEditableFieldsSetting<Receiver>>(
		"identity/editable-fields",
		"requester/receiver",
	) ?? {value: undefined};
	const {value: {usingTocco} = {usingTocco: false}} = getSetting<ToccoSetting>("tocco", "organization/requesters") ?? {};
	const {
		session,
		setSession,
		slot,
		filters,
		initialProvider,
		initialExcludedInterpreterIds,
		loadingProviders,
		setLoadingProviders,
	} = React.useContext(SessionCreationContext);

	// Sets default value for toccoPeopleNumber each time communication type changes to keep things consistent
	React.useEffect(
		() => {
			if (usingTocco) {
				setSession(prev => ({
					...prev,
					toccoPeopleNumber: TOCCO_DEFAULT_PERSON_NUMBER,
				}));
			}
		},
		[setSession, usingTocco, session.communication?.type],
	);

	const {setFilters} = React.useContext(ActivitiesContext);

	const showInterpretersSection = !!toXInterpreter;
	const showDetailsSection =
		!!subject ||
		(session.type === "interpreterMandate" && (!!paymentAuthority || !!sessionCategory));
	const showParticipantsSection = session.type === "interpreterMandate" && (
		!!requester ||
		!!receiver ||
		!!medicalProfessional ||
		!!otherProfessional
	);
	const showMediationSection = session.providedService?.type === "mediation" && (
		!!involvedPeople ||
		!!involvedCorporations ||
		!!reason ||
		!!goal ||
		!!expectation
	);
	const showCommentsSection = !!interpreterComment || !!internalComment;

	const [validations, setValidations] = React.useState({
		comments: !showCommentsSection,
		details: !showDetailsSection,
		interpreters: !showInterpretersSection,
		mediation: !showMediationSection,
		participants: !showParticipantsSection,
	});

	const onInterpretersSectionValidation = React.useCallback(
		(isValid: boolean) => setValidations(prev => ({...prev, interpreters: isValid})),
		[],
	);
	const onDetailsSectionValidation = React.useCallback(
		(isValid: boolean) => setValidations(prev => ({...prev, details: isValid})),
		[],
	);
	const onParticipantsSectionValidation = React.useCallback(
		(isValid: boolean) => setValidations(prev => ({...prev, participants: isValid})),
		[],
	);
	const onMediationSectionValidation = React.useCallback(
		(isValid: boolean) => setValidations(prev => ({...prev, mediation: isValid})),
		[],
	);
	const onCommentsSectionValidation = React.useCallback(
		(isValid: boolean) => setValidations(prev => ({...prev, comments: isValid})),
		[],
	);

	const getSubjectSuggestions = React.useCallback(
		(pagination: Pagination) => getSubjectSuggestionsRequest(pagination, accountId!),
		[accountId],
	);

	const searchSubjectSuggestions = React.useCallback(
		(pagination: Pagination, search: string) => searchSubjectSuggestionsRequest(pagination, search, accountId!),
		[accountId],
	);

	const getPaymentAuthorities = React.useCallback(
		(pagination: Pagination) => getPaymentAuthoritiesRequest(accountId!, pagination),
		[accountId],
	);

	const onSearchPaymentAuthorities = React.useMemo(
		() => ({
			request: (pagination: Pagination, search: string) => getPaymentAuthoritiesRequest(
				accountId!, pagination, search),
		}),
		[accountId],
	);

	const onChangePaymentAuthority = React.useCallback(
		(pa: PaymentAuthority[] | null) =>
			setSession(prev => ({
				...prev,
				paymentAuthority: pa?.[0],
			})),
		[setSession],
	);

	const onPressAddPaymentAuthority = React.useMemo(
		() => paymentAuthority?.editRights?.canAddNew
			? {
				request: (added: PaymentAuthority) => addPaymentAuthority(accountId!, added),
				screen: {
					name: "EditPaymentAuthorityModal" as const,
				},
			}
			: undefined,
		[accountId, paymentAuthority?.editRights?.canAddNew],
	);

	const getBillingAddresses = React.useCallback(
		(pagination: Pagination) => getBillingAddressesRequest(accountId!, pagination),
		[accountId],
	);

	const onSearchBillingAddresses = React.useMemo(
		() => ({
			request: (pagination: Pagination, search: string) => getBillingAddressesRequest(
				accountId!,
				pagination,
				search,
			),
		}),
		[accountId],
	);

	const onChangeBillingAddress = React.useCallback(
		(billingAddress: BillingAddress[] | null) =>
			setSession(prev => ({
				...prev,
				billingAddress: billingAddress?.[0],
			})),
		[setSession],
	);

	const onPressAddBillingAddress = React.useMemo(
		() => billingAddress?.editRights?.canAddNew
			? {
				request: (added: BillingAddress) => addBillingAddress(accountId!, added),
				screen: {name: "EditBillingAddressModal"},
			}
			: undefined,
		[accountId, billingAddress?.editRights?.canAddNew],
	);

	const getSessionCategories = React.useCallback(
		(pagination: Pagination) => getSessionCategoriesRequest(accountId!, pagination),
		[accountId],
	);

	const onSearchSessionCategories = React.useMemo(
		() => ({
			request: (pagination: Pagination, search: string) => getSessionCategoriesRequest(
				accountId!, pagination, search),
		}),
		[accountId],
	);

	const onChangeSessionCategory = React.useCallback(
		(sc: SessionCategory[] | null) =>
			setSession(prev => ({
				...prev,
				category: sc?.[0],
			})),
		[setSession],
	);

	const onPressAddSessionCategory = React.useMemo(
		() => sessionCategory?.editRights?.canAddNew
			? {
				request: (added: SessionCategory) => addSessionCategory(accountId!, added),
				screen: {
					name: "EditSessionCategoryModal" as const,
				},
			}
			: undefined,
		[accountId, sessionCategory?.editRights?.canAddNew],
	);

	React.useEffect(
		() => {
			if (session.immediate) {
				setLoadingProviders(true);
				getInterpretersImmediate(session, filters, initialProvider)
					.then(providers => setSession(prev => ({
						...prev,
						// filter the new available interpreters with initialExcludedInterpreterIds and reset excludedInterpreters
						excludedInterpreters: [],
						providers: providers.filter(p => !initialExcludedInterpreterIds.includes(p.accountId)),
					})))
					.catch(Log.error())
					.finally(() => setLoadingProviders(false));
			}
		},
		// We want to redo the request only when the data from the setup-step has changed, not the entire session.
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			filters,
			session.toLanguage,
			session.immediate,
			session.providedService.type,
			session.communication,
			session.duration,
			setLoadingProviders,
			setSession,
			initialProvider,
			initialExcludedInterpreterIds,
		],
	);

	const onGet = React.useCallback(
		(type: ClientStackParamList["SelectPersonModal"]["exportType"]) => selectPersonConfig(
			type, accountId!, session.activityId).get,
		[accountId, session.activityId],
	);

	const onSearch = React.useCallback(
		(type: ClientStackParamList["SelectPersonModal"]["exportType"]) => selectPersonConfig(
			type, accountId!, session.activityId).onSearch,
		[accountId, session.activityId],
	);

	const onValidation = React.useCallback(
		() => navigation.navigate(
			"SessionModal",
			{
				onSessionCreationFailed: onError,
				onSessionCreationSuccess: () => {
					setFilters({status: ["sent"]});
					navigation.navigate("HomeTabNavigator", {screen: "Calendar"});
					onValidate?.();
				},
				sessionId: "",
			},
		),
		[setFilters, navigation, onValidate, onError],
	);

	const onChangeValueSubject = React.useCallback(
		(subject: string | null) => setSession(prev => ({...prev, subject: subject ?? undefined})),
		[setSession],
	);

	const getLabelPerson = React.useMemo(
		() => (person: PersonPreview) => personIdentity(person),
		[],
	);

	const getLabelReceiver = React.useMemo(
		() => (receiver: ReceiverPreview) => receiverIdentity(
			receiver,
			receiverListedFields,
			getDescriptionLabel(receiverEditableFields),
		),
		[receiverEditableFields, receiverListedFields],
	);

	const onChangeRequester = React.useCallback(
		(people: PersonPreview[] | null) =>
			setSession(prev => ({
				...prev,
				requester: people?.[0],
			})),
		[setSession],
	);

	const onChangeReceiver = React.useCallback(
		(people: ReceiverPreview[] | null) =>
			setSession(prev => ({
				...prev,
				receiver: people?.[0],
			})),
		[setSession],
	);

	const onChangeMedicalProfessional = React.useCallback(
		(people: MedicalProfessionalPreview[] | null) =>
			setSession(prev => ({
				...prev,
				medicalProfessional: people?.[0],
			})),
		[setSession],
	);

	const onChangeProfessional = React.useCallback(
		(people: OtherProfessionalPreview[] | null) =>
			setSession(prev => ({
				...prev,
				otherProfessional: people?.[0],
			})),
		[setSession],
	);

	const onPressAddPerson = React.useCallback(
		(
			type: "medicalProfessional" | "otherProfessional" | "receiver" | "requester",
		): DropdownSelectListProps<"identityId", PersonIdentityPreview, "EditPersonModal">["onPressAdd"] => {
			const addRequest = selectPersonConfig(type, accountId!, session.activityId).onAdd;
			return addRequest && {
				request: addRequest,
				screen: {
					name: "EditPersonModal",
					params: {
						context: `requester/${type}`,
						title: ct("forms:items.add", {item: ct(`common:${type}`)}),
					},
				},
			};
		},
		[accountId, ct, session.activityId],
	);

	const toXInterpreterInputs: FormInput[] = React.useMemo(
		() => (
			[
				!!toXInterpreter && {
					disabled: !!initialProvider && session.providers.length === 1,
					icon: session.providers.length === 1 ? "person" : "people",
					key: "providers",
					label: ct("screens:creation.toXInterpreters", {n: session.providers.length}),
					resetable: false,
					rules: toXInterpreter?.mandatory && [{rule: Rules.notEmpty, type: "error"}],
					type: {
						getItems: {
							onChangeValue: (items: WaveInterpreterProvider[] | null) => {
								setSession(prev => ({
									...prev,
									excludedInterpreters: [...prev.providers, ...prev.excludedInterpreters]
										.filter(el => (items ?? []).every(item => item.identityId !== el.identityId)),
									providers: items ?? [],
								}));
							},
							params: {
								exportType: "interpreterPool",
								pool: [...session.providers, ...session.excludedInterpreters],
								selectionOrBase: session.providers,
							},
							screen: "SelectPersonModal",
						},
						getLabelText: (item: WaveInterpreterProvider) => personIdentity(item),
						itemProps: (item: WaveInterpreterProvider) => ({
							contentColor: session.communication?.type === "inPerson" && item.wave === "first"
								? PRIMARY
								: PRIMARY_2,
						}),
						key: "multiSelect",
						keyExtractor: (item: WaveInterpreterProvider) => item.identityId,
						onDeleteItem: (item: WaveInterpreterProvider) => {
							setSession(prev => ({
								...prev,
								excludedInterpreters: [...prev.excludedInterpreters, item],
								providers: prev.providers.filter(el => el.identityId !== item.identityId),
							}));
							return Promise.resolve();
						},
						value: session.providers.sort(
							(a, b) => (a.wave ? waveTypes.indexOf(a.wave) : 0) - (b.wave ? waveTypes.indexOf(b.wave) : 0)),
					},
				},
			]
		),
		[
			ct, initialProvider, session.communication?.type, session.excludedInterpreters, session.providers, setSession,
			toXInterpreter,
		],
	);

	const subjectInput: FormInput = React.useMemo(
		() => (
			!!subject && {
				icon: "id",
				label: ct("common:subject"),
				rules: subject?.mandatory && [{rule: Rules.notEmpty, type: "error"}],
				type: mobileDisplay
					? {
						key: "screen",
						onChangeValue: (subject: TextInputDropdownSuggestionItem | null) =>
							setSession(prev => ({...prev, subject: subject?.displayed})),
						params: {
							onPressSelect: {
								canSelectSearch: true,
								disableMultipleSelection: true,
								onPress: (subject: TextInputDropdownSuggestionItem[] | string) =>
									setSession(prev => ({...prev, subject: Array.isArray(subject) ? subject?.[0].id : subject})),
							},
						},
						renderValue: (value: string) => value,
						screen: "SelectSubjectSuggestionModal",
						value: session?.subject,
					}
					: {
						getRequest: getSubjectSuggestions,
						idKey: "id",
						itemTranslationKey: "common:subject",
						key: "textSuggestions",
						onSearch: {
							onChangeSearch: onChangeValueSubject,
							placeholder: session?.subject ? undefined : t("activities:sessions.subjectPlaceholder"),
							request: searchSubjectSuggestions,
							value: session?.subject,
						},
					},
			}
		),
		[
			ct, getSubjectSuggestions, mobileDisplay, onChangeValueSubject, searchSubjectSuggestions,
			session?.subject, setSession, subject, t,
		],
	);

	const paymentAuthorityInput: FormInput = React.useMemo(
		() => (
			!usingTocco && session.type === "interpreterMandate" && !!paymentAuthority && {
				icon: "payment",
				label: paymentAuthority.label ?? ct("common:paymentAuthority"),
				rules: paymentAuthority.mandatory && [{rule: Rules.notEmpty, type: "error"}],
				type: mobileDisplay
					? {
						key: "screen",
						onChangeValue: (value: PaymentAuthority) => setSession(prev => ({...prev, paymentAuthority: value})),
						renderValue: getLabelPaymentAuthorities,
						screen: "SelectPaymentAuthorityModal",
						value: session.paymentAuthority,
					}
					: {
						getLabelText: getLabelPaymentAuthorities,
						getRequest: getPaymentAuthorities,
						idKey: "id",
						itemTranslation: paymentAuthority.label ?? t("common:paymentAuthority"),
						key: "dropdownSelect",
						onChangeValue: onChangePaymentAuthority,
						onPressAdd: onPressAddPaymentAuthority,
						onSearch: onSearchPaymentAuthorities,
						value: session.paymentAuthority ? [session.paymentAuthority] : null,
					},
			}
		),
		[
			ct, getPaymentAuthorities, mobileDisplay, onChangePaymentAuthority, onPressAddPaymentAuthority,
			onSearchPaymentAuthorities, paymentAuthority, session.paymentAuthority, session.type, setSession, t, usingTocco,
		],
	);

	const sessionCategoryInput: FormInput = React.useMemo(
		() => (
			session.type === "interpreterMandate" && !!sessionCategory && {
				icon: "category",
				label: sessionCategory.label ?? ct("common:sessionCategory"),
				rules: sessionCategory.mandatory && [{rule: Rules.notEmpty, type: "error"}],
				type: mobileDisplay
					? {
						key: "screen",
						onChangeValue: (value: SessionCategory) => setSession(prev => ({...prev, category: value})),
						renderValue: getLabelSessionCategory,
						screen: "SelectSessionCategoryModal",
						value: session.category,
					}
					: {
						getLabelText: getLabelSessionCategory,
						getRequest: getSessionCategories,
						idKey: "id",
						itemTranslation: sessionCategory.label ?? t("common:sessionCategory"),
						key: "dropdownSelect",
						onChangeValue: onChangeSessionCategory,
						onPressAdd: onPressAddSessionCategory,
						onSearch: onSearchSessionCategories,
						value: session.category ? [session.category] : null,
					},
			}
		),
		[
			ct, getSessionCategories, mobileDisplay, onChangeSessionCategory, onPressAddSessionCategory,
			onSearchSessionCategories, session.category, session.type, sessionCategory, setSession, t,
		],
	);

	const billingAddressInput: FormInput = React.useMemo(
		() => (
			usingTocco && {
				icon: "address",
				label: ct("common:billingAddress"),
				type: mobileDisplay
					? {
						key: "screen",
						onChangeValue: (value: BillingAddress) => setSession(prev => ({...prev, billingAddress: value})),
						renderValue: getLabelBillingAddress,
						screen: "SelectBillingAddressModal",
						value: session.billingAddress,
					}
					: {
						getLabelText: getLabelBillingAddress,
						getRequest: getBillingAddresses,
						idKey: "id",
						itemTranslation: t("common:billingAddress"),
						key: "dropdownSelect",
						onChangeValue: onChangeBillingAddress,
						onPressAdd: onPressAddBillingAddress,
						onSearch: onSearchBillingAddresses,
						value: session.billingAddress ? [session.billingAddress] : null,
					},
			}
		),
		[
			ct, getBillingAddresses, mobileDisplay, onChangeBillingAddress, onPressAddBillingAddress, onSearchBillingAddresses,
			session.billingAddress, setSession, t, usingTocco,
		],
	);

	const toccoBillingInformationInput: FormInput = React.useMemo(
		() => (
			usingTocco && {
				icon: "comment",
				label: t("activities:sessions.toccoBillingInformation.label"),
				type: {
					key: "text",
					onChangeValue: (toccoBillingInformation: string) => setSession(prev => ({...prev, toccoBillingInformation})),
					props: {multiline: true, placeholder: t("activities:sessions.toccoBillingInformation.placeholder")},
					value: session.toccoBillingInformation,
				},
			}
		),
		[t, session.toccoBillingInformation, setSession, usingTocco],
	);

	const detailsSectionInputs: FormInput[] = React.useMemo(
		() => ([
			subjectInput,
			paymentAuthorityInput,
			sessionCategoryInput,
			billingAddressInput,
			toccoBillingInformationInput,
		]),
		[billingAddressInput, paymentAuthorityInput, sessionCategoryInput, subjectInput, toccoBillingInformationInput],
	);

	const requesterInput: FormInput = React.useMemo(
		() => (
			!!requester && {
				icon: "personOutline",
				label: ct("common:requester"),
				rules: requester?.mandatory && [{rule: Rules.notEmpty, type: "error"}],
				type: mobileDisplay
					? {
						key: "screen",
						onChangeValue: (people: PersonPreview[] | null) => setSession(prev => ({...prev, requester: people?.[0]})),
						params: {exportType: "requester"},
						renderValue: personIdentity,
						screen: "SelectPersonModal",
						value: session.requester,
					}
					: {
						getLabelText: personIdentity,
						getRequest: onGet("requester"),
						idKey: "identityId",
						itemTranslationKey: "common:requester",
						key: "dropdownSelect",
						onChangeValue: onChangeRequester,
						onPressAdd: onPressAddPerson("requester"),
						onSearch: onSearch("requester"),
						value: session.requester ? [session.requester] : undefined,
					},
			}
		),
		[ct, mobileDisplay, onChangeRequester, onGet, onPressAddPerson, onSearch, requester, session.requester, setSession],
	);

	const receiverInput: FormInput = React.useMemo(
		() => (
			!usingTocco && !!receiver && {
				icon: "receiver",
				label: receiver?.label ?? ct("common:receiver"),
				rules: receiver?.mandatory && [{rule: Rules.notEmpty, type: "error"}],
				type: mobileDisplay
					? {
						key: "screen",
						onChangeValue: (people: ReceiverPreview[]) => setSession(prev => ({...prev, receiver: people?.[0]})),
						params: {exportType: "receiver"},
						renderValue: (value: ReceiverPreview) => receiverIdentity(
							value,
							receiverListedFields,
							getDescriptionLabel(receiverEditableFields),
						),
						screen: "SelectPersonModal",
						value: session.receiver,
					}
					: {
						getLabelText: getLabelReceiver,
						getRequest: onGet("receiver"),
						idKey: "identityId",
						itemTranslation: receiver?.label ?? t("common:receiver"),
						key: "dropdownSelect",
						onChangeValue: onChangeReceiver,
						onPressAdd: onPressAddPerson("receiver"),
						onSearch: onSearch("receiver"),
						value: session.receiver ? [session.receiver] : undefined,
					},
			}
		),
		[
			ct, getLabelReceiver, mobileDisplay, onChangeReceiver, onGet, onPressAddPerson, onSearch, receiver,
			receiverEditableFields, receiverListedFields, session.receiver, setSession, t, usingTocco,
		],
	);

	const medicalProfessionalInput: FormInput = React.useMemo(
		() => (
			!!medicalProfessional && {
				icon: "medical",
				label: medicalProfessional?.label ?? ct("common:medicalProfessional"),
				rules: medicalProfessional?.mandatory && [{rule: Rules.notEmpty, type: "error"}],
				type: mobileDisplay
					? {
						key: "screen",
						onChangeValue: (people: MedicalProfessionalPreview[] | null) => setSession(prev => ({
							...prev,
							medicalProfessional: people?.[0],
						})),
						params: {exportType: "medicalProfessional"},
						renderValue: personIdentity,
						screen: "SelectPersonModal",
						value: session.medicalProfessional,
					}
					: {
						getLabelText: getLabelPerson,
						getRequest: onGet("medicalProfessional"),
						idKey: "identityId",
						itemTranslation: medicalProfessional?.label ?? t("common:medicalProfessional"),
						key: "dropdownSelect",
						onChangeValue: onChangeMedicalProfessional,
						onPressAdd: onPressAddPerson("medicalProfessional"),
						onSearch: onSearch("medicalProfessional"),
						value: session.medicalProfessional ? [session.medicalProfessional] : undefined,
					},
			}
		),
		[
			ct, getLabelPerson, medicalProfessional, mobileDisplay, onChangeMedicalProfessional, onGet, onPressAddPerson,
			onSearch, session.medicalProfessional, setSession, t,
		],
	);

	const otherProfessionalInput: FormInput = React.useMemo(
		() => (
			!!otherProfessional && {
				icon: "person",
				label: otherProfessional?.label ?? t("screens:creation.otherProfessional"),
				rules: otherProfessional?.mandatory && [{rule: Rules.notEmpty, type: "error"}],
				type: mobileDisplay
					? {
						key: "screen",
						onChangeValue: (people: OtherProfessionalPreview[] | null) => setSession(prev => ({
							...prev,
							otherProfessional: people?.[0],
						})),
						params: {exportType: "otherProfessional"},
						renderValue: personIdentity,
						screen: "SelectPersonModal",
						value: session.otherProfessional,
					}
					: {
						getLabelText: getLabelPerson,
						getRequest: onGet("otherProfessional"),
						idKey: "identityId",
						itemTranslation: otherProfessional?.label ?? t("screens:creation.otherProfessional"),
						key: "dropdownSelect",
						onChangeValue: onChangeProfessional,
						onPressAdd: onPressAddPerson("otherProfessional"),
						onSearch: onSearch("otherProfessional"),
						value: session.otherProfessional ? [session.otherProfessional] : undefined,
					},
			}
		),
		[
			getLabelPerson, mobileDisplay, onChangeProfessional, onGet, onPressAddPerson, onSearch, otherProfessional,
			session.otherProfessional, setSession, t,
		],
	);

	const toccoPeopleNumberInput = useToccoPeopleNumberInput(usingTocco, session.communication?.type, setSession, session);

	const participantsInputs: FormInput[] = React.useMemo(
		() => ([
			requesterInput,
			receiverInput,
			medicalProfessionalInput,
			otherProfessionalInput,
			toccoPeopleNumberInput,
		]),
		[medicalProfessionalInput, otherProfessionalInput, receiverInput, requesterInput, toccoPeopleNumberInput],
	);

	return (
		<>
			{/* we add the StatusBar because detailsStep comes on top of scheduleStep with absoluteFill */}
			<StatusBar/>
			<HeaderMenu left={<Icon icon="back" onPress={onBack}/>} center={t("screens:creation.myAppointment")}/>
			<KeyboardAvoidingView style={styles.scrollView}>
				<ScrollView>
					<View style={[CONTAINERS.MAIN, styles.headerContainer]}>
						{columns === 2 && !session.immediate && (
							<>
								<Icon icon="back" onPress={onBack}/>
								<Spacer size={DEFAULT_SPACING} mode="vertical"/>
							</>
						)}
						<View style={styles.header}>
							<Icon icon={getCommunicationTypeIcon(session.communication!.type)} size={DEFAULT_SPACING}/>
							<Separator mode="vertical"/>
							<Text type="emphasis_1">
								{formatShortestDate(session.start!)}
							</Text>
							<Separator mode="vertical"/>
							<Text type="emphasis_1">
								{session.immediate ? ct("common:immediate") : formatShortTimeRange(slot!.start, slot!.end)}
							</Text>
							<Separator mode="vertical"/>
							<Text type="emphasis_1">
								{t(`languages:${session.toLanguage!}`)}
							</Text>
						</View>
					</View>
					{showInterpretersSection && (
						<Form
							contentContainerStyle={styles.form}
							title={ct("common:interpreter_plural")}
							sideTitleComponent={
								session.providers.length > 0 &&
								session.communication?.type === "inPerson" &&
								<Text color={BLUE} style={styles.proximity}>{t("screens:creation.proximity")}</Text>
							}
							hideReset
							requiredLabels
							inputs={toXInterpreterInputs}
							onValidationChange={onInterpretersSectionValidation}
						/>
					)}
					{showDetailsSection && (
						<Form
							contentContainerStyle={styles.form}
							title={ct("common:detail_plural")}
							hideReset
							requiredLabels
							inputs={detailsSectionInputs}
							onValidationChange={onDetailsSectionValidation}
						/>
					)}
					{showParticipantsSection && (
						<Form
							contentContainerStyle={styles.form}
							title={t("screens:creation.participants")}
							hideReset
							requiredLabels
							inputs={participantsInputs}
							onValidationChange={onParticipantsSectionValidation}
						/>
					)}
					{showMediationSection && session.providedService?.type === "mediation" && (
						<Form
							contentContainerStyle={styles.form}
							title={t("activities:sessions.mediation.title")}
							hideReset
							requiredLabels
							inputs={[
								!!involvedPeople && {
									icon: "people",
									label: t("activities:sessions.mediation.involvedPeople"),
									rules: involvedPeople?.mandatory && [{rule: Rules.notEmpty, type: "error"}],
									type: {
										getItems: {
											onChangeValue: (items: PersonIdentityPreview[] | null) =>
												setSession(prev => ({
													...prev,
													providedService: {
														...prev.providedService,
														involvedPeople: items?.length ? items : [],
													},
												})),
											params: {
												exportType: "receiver",
												selectionOrBase: session.providedService.involvedPeople ?? [],
											},
											screen: "SelectPersonModal",
										},
										getLabelText: (item: PersonIdentityPreview) => personIdentity(item),
										key: "multiSelect",
										keyExtractor: (item: PersonIdentityPreview) => item.identityId,
										onAddItem: {
											request: (item: PersonIdentityPreview) => {
												const p: PersonIdentityPreview = {...item, identityId: randomString()};
												return Promise.resolve(setSession(prev => ({
													...prev,
													providedService: {
														...prev.providedService,
														involvedPeople: [...((prev?.providedService as Mediation)?.involvedPeople ?? []), p],
													},
												})));
											},
											screen: {
												name: "EditPersonModal",
												params: {
													authorizedFields: {
														// [editable, mandatory, availableValues]
														description: [true, true, null],
														fullName: [
															true, true, {
																firstName: [true, false, null],
																lastName: [true, true, null],
															},
														],
														gender: [true, true, null],
													},
												},
											},
										},
										onDeleteItem: (item: PersonIdentityPreview) =>
											Promise.resolve(setSession(prev => {
												const prevMed = (prev.providedService as Mediation);
												const value = prevMed.involvedPeople.filter(el => el.identityId !== item.identityId);
												return {
													...prev,
													providedService: {
														...prevMed,
														involvedPeople: value,
													},
												};
											})),
										onUpdateItem: {
											request: (item: PersonIdentityPreview) =>
												Promise.resolve(setSession(prev => {
													const prevMed = (prev.providedService as Mediation);
													const {involvedPeople} = prevMed;
													const index = involvedPeople.findIndex(i => i.identityId === item.identityId);
													return ({
														...prev,
														providedService: {
															...prevMed,
															involvedPeople: [
																...(involvedPeople?.slice(0, index) || []),
																item,
																...(involvedPeople?.slice(index + 1) || []),
															],
														},
													});
												})),
											screen: {
												name: "EditPersonModal",
												params: {
													authorizedFields: {
														// [editable, mandatory, availableValues]
														description: [true, true, null],
														fullName: [
															true, true, {
																firstName: [true, false, null],
																lastName: [true, true, null],
															},
														],
														gender: [true, true, null],
													},
												},
											},
										},
										value: session.providedService.involvedPeople,
									},
								},
								!!involvedCorporations && {
									icon: "corporation",
									label: t("activities:sessions.mediation.involvedCorporations"),
									rules: involvedCorporations?.mandatory && [{rule: Rules.notEmpty, type: "error"}],
									type: {
										getItems: {
											onChangeValue: (items: Corporation[] | null) =>
												setSession(prev => ({
													...prev,
													providedService: {
														...(prev.providedService as Mediation),
														involvedCorporations: items?.length ? items : [],
													},
												})),
											params: {
												authorizedFields: {
													// [editable, mandatory, availableValues]
													description: [true, true, null],
													name: [true, true, null],
												},
											},
											screen: "EditCorporationModal",
										},
										getLabelText: (item: Corporation) => corporationIdentity(item),
										key: "multiSelect",
										keyExtractor: (item: Corporation) => item.identityId,
										onAddItem: {
											request: (item: Corporation) => {
												const c: Corporation = {...item, identityId: randomString()};
												return Promise.resolve(setSession(prev => ({
													...prev,
													providedService: {
														...(prev.providedService as Mediation),
														involvedCorporations: [
															...((prev.providedService as Mediation).involvedCorporations ?? []),
															c,
														],
													},
												})));
											},
											screen: {
												name: "EditCorporationModal",
												params: {
													authorizedFields: {
														// [editable, mandatory, availableValues]
														description: [true, true, null],
														name: [true, true, null],
													},
												},
											},
										},
										onDeleteItem: (item: Corporation) =>
											Promise.resolve(setSession(prev => {
												const prevMed = prev.providedService as Mediation;
												const value = prevMed.involvedCorporations.filter(el => el.identityId !== item.identityId);
												return {
													...prev,
													providedService: {...prevMed, involvedCorporations: value},
												};
											})),
										onUpdateItem: {
											request: (item: Corporation) =>
												Promise.resolve(setSession(prev => {
													const prevMed = prev.providedService as Mediation;
													const index = prevMed.involvedCorporations.findIndex(i => i.identityId === item.identityId);
													return ({
														...prev,
														involvedCorporations: [
															...(prevMed.involvedCorporations?.slice(0, index) ?? []),
															item,
															...(prevMed.involvedCorporations?.slice(index + 1) ?? []),
														],
													});
												})),
											screen: {
												name: "EditCorporationModal",
												params: {
													authorizedFields: {
														// [editable, mandatory, availableValues]
														description: [true, true, null],
														name: [true, true, null],
													},
												},
											},
										},
										value: session.providedService.involvedCorporations,
									},
								},
								!!reason && {
									icon: "comment",
									label: t("activities:sessions.mediation.reason"),
									rules: reason?.mandatory && [{rule: Rules.notEmpty, type: "error"}],
									type: {
										key: "text",
										onChangeValue: (reason: string) => setSession(prev => ({
											...prev,
											providedService: {
												...prev.providedService,
												reason,
											},
										})),
										value: session.providedService.reason,
									},
								},
								!!goal && {
									icon: "comment",
									label: t("activities:sessions.mediation.goal"),
									rules: goal?.mandatory && [{rule: Rules.notEmpty, type: "error"}],
									type: {
										key: "text",
										onChangeValue: (goal: string) => setSession(prev => ({
											...prev,
											providedService: {
												...prev.providedService,
												goal,
											},
										})),
										value: session.providedService.goal,
									},
								},
								!!expectation && {
									icon: "comment",
									label: t("activities:sessions.mediation.expectation"),
									rules: expectation?.mandatory && [{rule: Rules.notEmpty, type: "error"}],
									type: {
										key: "text",
										onChangeValue: (expectation: string) => setSession(prev => ({
											...prev,
											providedService: {
												...prev.providedService,
												expectation,
											},
										})),
										value: session.providedService.expectation,
									},
								},
							]}
							onValidationChange={onMediationSectionValidation}
						/>
					)}
					{showCommentsSection && (
						<Form
							contentContainerStyle={styles.form}
							title={t("screens:creation.comments")}
							hideReset
							requiredLabels
							inputs={[
								!!interpreterComment && {
									icon: "commentMore",
									label: t("activities:sessions.interpreterComment"),
									rules: interpreterComment?.mandatory && [{rule: Rules.notEmpty, type: "error"}],
									type: {
										key: "text",
										onChangeValue: (providerComment: string) => setSession(prev => ({...prev, providerComment})),
										props: {multiline: true, placeholder: t("activities:sessions.interpreterCommentPlaceholder")},
										value: session.providerComment,
									},
								}, !!internalComment && {
									icon: "commentMoreOutline",
									label: t("activities:sessions.internalComment"),
									rules: internalComment?.mandatory && [{rule: Rules.notEmpty, type: "error"}],
									type: {
										key: "text",
										onChangeValue: (internalComment: string) => setSession(prev => ({...prev, internalComment})),
										props: {multiline: true, placeholder: t("activities:sessions.internalCommentPlaceholder")},
										value: session.internalComment,
									},
								},
							]}
							onValidationChange={onCommentsSectionValidation}
						/>
					)}
					{session.providedService?.type === "mediation" && (
						<View style={[CONTAINERS.MAIN, styles.informationContainer]}>
							<Text type="default_2" color={SUBTLE_4} centered>{
								t("activities:sessions.mediation.information")}
							</Text>
						</View>
					)}
					<View style={[CONTAINERS.MAIN, styles.buttonContainer]}>
						<Button
							size="small"
							fullWidth
							text={ct("common:preview")}
							icon="chevronRight"
							iconPosition="after"
							onPress={onValidation}
							disabled={Object.values(validations).includes(false)}
						/>
					</View>
				</ScrollView>
				{(loadingProviders || (session.providers.length === 0 && session.excludedInterpreters.length === 0)) && (
					<View style={[
						styles.overlay,
						{
							backgroundColor: session.providers.length > 0 || session.excludedInterpreters.length > 0
								? WHITE_OVERLAY
								: SUBTLE,
						},
					]}>
						{columns < 3 &&
							(loadingProviders
								? <ActivityIndicator color={SUBTLE_4} size={LOADER_SIZE}/>
								: <>
									<Text type="title_2" centered>{ct(
										"screens:creation.scheduleOverlay.noInterpreterAvailable")}</Text>
									<Text type="title_2" centered>{ct(
										"screens:creation.scheduleOverlay.modifyForm")}</Text>
								</>
							)
						}
					</View>
				)}
			</KeyboardAvoidingView>
		</>
	);
};

const styles = StyleSheet.create({
	buttonContainer: {
		padding: DEFAULT_SPACING,
	},
	form: {
		paddingVertical: 0,
	},
	header: {
		alignItems: "center",
		flex: 1,
		flexDirection: "row",
		justifyContent: "center",
	},
	headerContainer: {
		alignItems: "center",
		flex: 1,
		flexBasis: "auto",
		flexDirection: "row",
		justifyContent: "center",
		padding: DEFAULT_SPACING,
		paddingBottom: SMALL_SPACING,
	},
	informationContainer: {
		paddingHorizontal: DEFAULT_SPACING,
		paddingVertical: SMALL_SPACING,
	},
	overlay: {
		alignItems: "center",
		justifyContent: "center",
		paddingHorizontal: EXTRA_LARGE_SPACING,
		...StyleSheet.absoluteFillObject,
	},
	proximity: {
		marginLeft: DEFAULT_SPACING,
	},
	scrollView: {
		flex: 1,
	},
});
