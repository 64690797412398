import {Session, SessionCancelOrReschedule} from "../../../@types/activities/session";
import {api} from "../../../utils/network/api";
import {ApiResponse} from "../../../utils/network/api-response";

export type UserSessionCancelRequests = {
	POST: {
		pathParams: {
			sessionId: string,
		},
		body: {
			isCancelledByInterpreter: boolean,
			reasonToCancel: string,
		},
	},
};
export const cancelSession = async (
	sessionId: Session["activityId"], cancellation: SessionCancelOrReschedule,
): Promise<void> => {
	const {reason, canceledByProvider} = cancellation;
	const response = await api(
		"userSessionCancel",
		"POST",
		{
			pathParams: {sessionId},
			body: {
				isCancelledByInterpreter: canceledByProvider,
				reasonToCancel: reason,
			},
		},
	) as ApiResponse<{}>;
	return response.isSuccess ? Promise.resolve() : Promise.reject(response.message);
};
