/* eslint-disable quote-props */
import {activities as enUSActivities} from "../en-US/activities";

export const activities: typeof enUSActivities = {
	calendar: {
		abbreviation: {
			day: "j",
			hour: "h",
			minute: "m",
		},
		days: {
			friday: "vendredi",
			monday: "lundi",
			saturday: "samedi",
			sunday: "dimanche",
			thursday: "jeudi",
			tuesday: "mardi",
			wednesday: "mercredi",
		},
		exportedEvent: {
			editWarning: "Cet évènement est exporté par l'application Bhaasha. Toute modification manuelle de l'évènement sera perdue après la prochaine synchronisation.",
			personalCalendar: "Calendrier Personnel",
		},
		month: {
			april: "avril",
			august: "août",
			december: "décembre",
			february: "février",
			january: "janvier",
			july: "juillet",
			june: "juin",
			march: "mars",
			may: "mai",
			november: "novembre",
			october: "octobre",
			september: "septembre",
		},
		today: "Aujourd'hui · {{date}}",
	},
	event: {
		confirmDelete: "Supprimer cet évènement ?",
		confirmDeletes: "Supprimer tous les évènements (récurrence) ?",
		confirmUpdate: "Mettre à jour cet évènement ?",
		confirmUpdates: "Mettre à jour tous les évènements (récurrence) ?",
		created: "Évènement créé",
		recurrence: {
			connector: {
				end: "jusqu'au",
				every: "chaque",
				everyDay: "tous les jours",
				fromDate: "du",
				fromTime: "de",
				last: "dernier",
				month: "du mois",
				on: "le",
				start: "depuis",
				toDate: "au",
				toTime: "à",
			},
			error: {
				endTime: "L'heure de fin doit être après l'heure de début",
				startTime: "L'heure de début doit être avant l'heure de fin",
			},
			explanation: {
				endTime: "Pour afficher les récurrences, changez la date de fin",
				summary: "Récapitulatif",
			},
			ordinal: {
				fifth: "cinquième",
				first: "premier",
				fourth: "quatrième",
				second: "deuxième",
				third: "troisième",
			},
			type: {
				daily: "Journalier",
				monthly: "Mensuel",
				never: "Jamais",
				weekly: "Hebdomadaire",
				yearly: "Annuel",
			},
		},
	},
	price: {
		abbrev: {
			base: "Base",
			increased: "Suppl.",
			reduced: "Réduit",
			travel: {
				distance: "Distance",
				flatRate: "Forfait",
				time: "Temps de trajet",
				total: "Total dépl.",
			},
		},
		base: "Tarif de base",
		increased: "Tarif supplémentaire",
		reduced: "Tarif réduit",
	},
	sessions: {
		accept: {
			assigned: "Merci. vous avez été assigné la session #{{id}}.",
			validation: "Êtes-vous sûr d'accepter cette session?",
			viewAction: "Voir session",
		},
		acceptanceTime: "Heure d'acceptation",
		address: "Adresse exacte",
		addressInfos: "Emplacement (nom de l'institution, bâtiment, étage, salle, ...)",
		appliedFilter: "{{count}} filtre appliqué",
		appliedFilter_plural: "{{count}} filtres appliqués",
		call: {
			done: "Appel terminé il y a {{duration}}",
			lessOneMinute: "moins d'une minute", // first letter must be lowercase!
			shareDescription: "Si un participant ne parvient pas à trouver le lien de la session vidéo, utilisez le bouton ci-dessous pour le lui renvoyer. Ce lien est exclusivement destiné aux clients et ne doit en aucun cas être utilisé par l'interprète.",
			start: "L'appel démarrera dans {{duration}}",
			started: "L'appel a démarré il y a {{duration}}",
		},
		cancelConfirmation: "Voulez-vous vraiment annuler cette session?",
		canceled: "La session a été annulée",
		canceledByInterpreter: "Annulation dûe à l'interprète (pas de facturation)",
		cancellationReason: "Raison de l'annulation",
		communicationTypes: {
			inPerson: "En personne",
			phone: "Téléphone",
			video: "Vidéo",
		},
		context: {
			confirmReset: "Êtes-vous sûr de vouloir réinitialiser cette session? Toutes les données que vous avez déjà saisies seront effacées.",
			message: {
				subtitle: "{{user}}: {{message}}",
				title: "Nouveau message",
			},
			refused: {
				subtitle: "Aucun interprète trouvé pour cette session",
				title: "Session refusée",
			},
			reschedule: {
				subtitle: "{{user}} veut replanifier la session",
				title: "Session replanifiée",
			},
			type: {
				explanation: "Si vous ne terminez pas le processus de création de session, la session initiale ne sera pas replanifiée",
				withProvider: {
					followUp: "Veuillez saisir les détails pour envoyer la session de suivi à {{provider}}",
					new: "Veuillez saisir les détails pour envoyer la demande à {{provider}}",
					reschedule: "Cette replanification sera envoyée à {{provider}}",
				},
				withoutProvider: {
					followUp: "Cette session est une session de suivi",
					new: "Cette session est une nouvelle session",
					reschedule: "Cette session est une replanification",
				},
			},
			validate: {
				subtitle: "Veuiller valider cette session",
				title: "Valider session",
			},
		},
		createNew: "Créez une nouvelle session",
		created: "La session a été crée",
		duplicate: {
			title: "Choisir une option de duplication",
			withInterpreter: "Avec interprète",
			withoutInterpreter: "Sans interprète",
		},
		durations: {
			"15": "15 minutes",
			"30": "30 minutes",
			"45": "45 minutes",
			"60": "1 heure",
			"75": "1 heure 15 minutes",
			"90": "1 heure 30 minutes",
			"105": "1 heure 45 minutes",
			"120": "2 heures",
			"135": "2 heures 15 minutes",
			"150": "2 heures 30 minutes",
			"165": "2 heures 45 minutes",
			"180": "3 heures",
			"195": "3 heures 15 minutes",
			"210": "3 heures 30 minutes",
			"225": "3 heures 45 minutes",
			"240": "4 heures (demi-journée)",
			"255": "4 heures 15 minutes",
			"270": "4 heures 30 minutes",
			"285": "4 heures 45 minutes",
			"300": "5 heures",
			"315": "5 heures 15 minutes",
			"330": "5 heures 30 minutes",
			"345": "5 heures 45 minutes",
			"360": "6 heures",
			"375": "6 heures 15 minutes",
			"390": "6 heures 30 minutes",
			"405": "6 heures 45 minutes",
			"420": "7 heures",
			"435": "7 heures 15 minutes",
			"450": "7 heures 30 minutes",
			"465": "7 heures 45 minutes",
			"480": "8 heures (jour complet)",
		},
		exportTitle: "Exporter la session",
		followUp: {
			calendar: "Voir calendrier",
			create: "Créer le suivi",
			created: "Merci. La session de suivi à été crée avec succès.",
			notes: "Notes & commentaires",
			validation: "Êtes-vous sûr de créer cette session de suivi?",
		},
		internalComment: "Commentaires internes (non visible par l'interprète)",
		internalCommentPlaceholder: "Ajouter des commentaires si nécessaire",
		interpreterComment: "Commentaires à l'interprète",
		interpreterCommentPlaceholder: "Nombre de participants, contenu de la réunion, termes spécifiques et autres informations utiles pour l'interprète",
		mediation: {
			expectation: "Attentes de la médiation interculturelle",
			goal: "But de la conversation",
			information: "Pour une médiation interculturelle, une discussion téléphonique préalable entre le contractant et le médiateur est nécessaire afin de permettre une conversation réussie. Le médiateur interculturel appellera le contractant à l'avance pour obtenir des informations détaillées sur les faits / cas et sera disponible pour un débriefing après l'entretien.",
			involvedCorporations: "Autres organismes impliqués",
			involvedPeople: "Personnes impliquées dans la conversation (rôles)",
			reason: "Raison de la conversation (définition du problème, hypothèses)",
			title: "Détails de médiation",
		},
		newStatus: {
			done: "La session est maintenant en status terminée et a durée {{duration}}",
			evaluated: "La session est maintenant en status évaluée",
			validated: "La session est maintenant en status validée",
		},
		price: "Honoraires",
		providedService: {
			education: "Cours",
			mediation: "Médiation",
			standard: "Interprétariat",
		},
		rating: "Mon évaluation",
		refusalReason: "Raison du refus",
		refuse: {
			validation: "Êtes-vous sûr de refuser cette session?",
		},
		reschedule: {
			accepted: "La replanification de session a été acceptée",
			confirmation: "Voulez-vous vraiment replanifier cette session?",
			excluded: "Ne pas solliciter les interprètes qui ont été exclus de la réservation précédente",
			reason: "Raison de la replanification",
			sent: "La replanification de session a été envoyée",
			toAllInterpreters: "Envoyer la nouvelle session à tous les interprètes",
			toOneInterpreter: "Envoyer la nouvelle session uniquement à {{interpreter}}",
		},
		sent: "La session a été envoyée a {{nInterpreters}} interprètes",
		shareInfo: "Session partagée le",
		status: {
			canceled: "Annulé",
			completed: "Complété",
			confirmed: "Confirmé",
			deleted: "Supprimé",
			inProgress: "En cours",
			refused: "Refusé",
			rescheduled: "Reprogrammé",
			sent: "En attente",
			stopped: "Stoppé",
			unvalidated: "Non-validé",
		},
		statusType: {
			alert: "Alertes",
			confirmed: "Confirmés",
			history: "Historique",
			sent: "En attente",
		},
		subjectPlaceholder: "Sur quoi porte cette session?",
		support: {
			cancelation: "Demander une annulation pour raison personnelle",
			deletion: "Demander une suppression pour erreur de saisie",
			other: "Autre demande de support",
			"validation-cards": "Commande de cartes de validation",
		},
		timerImmediate: {
			allRefused: "Malheureusement, aucun interprète n'est disponible - veuillez choisir une action pour continuer",
			cancel: "Annuler la recherche",
			continue: "la recherche continue pour",
			expired: "La session va bientôt être automatiquement refusée",
			inProgress: "Interprète en cours de recherche",
			notFound: "Aucun interprète trouvé",
			research: "Rechercher avec d'autres paramètres",
			responses: "Réponses",
			sentTo: "Envoyé à",
		},
		toccoBillingInformation: {
			label: "Informations pour la facturation",
			placeholder: "Vos coordonnées seront mentionnées sur la facture. Veuillez tenir compte de la protection des données!",
		},
		toccoPeopleNumber: "Nb. de personnes",
		travelFees: "Drais de déplacement",
		validate: {
			agreement: "Je confirme que les données entrées sont complètes et correspondent au déroulement de la session.",
			issue: {
				description: "Description",
				label: "Ajouter un changement ou incident",
				type: {
					absentReceiver: {
						label: "Bénéficiaire absent",
						person: "Personne absente",
						warning: "Attention! Ajouter l'issue \"bénéficiaire absent\" vous fera perdre l'incidents que vous avez déjà signalé.",
						warning_plural: "Attention! Ajouter l'issue \"bénéficiaire absent\" vous fera perdre les {{count}} incidents que vous avez déjà signalés.",
					},
					cancellationTime: {
						label: "Date et heure d'annulation",
					},
					communicationTypeChanged: {
						changedType: "Mode de communication utilisé",
						label: "Le mode de communication a changé",
						summary: "Passé de \"{{originalType}}\" à \"{{changedType}}\"",
					},
					effectiveEndTime: {
						effectiveEndTime: "Heure effective de fin",
						error: "L'heure effective de fin doit être après l'heure effective de début",
						explanation: "Validez le temps réel, pas le temps réservé.",
						label: "Heure effective de fin",
					},
					effectiveStartTime: {
						effectiveStartTime: "Heure effective de début",
						error: "L'heure effective de début doit être avant l'heure effective de fin",
						label: "Heure effective de début",
						warning: "Si le retard n'est pas dû à l'interprète, l'heure effective de début doit rester l'heure initialement prévue de la session.",
					},
					feedback: {
						label: "Feedback",
					},
					incident: {
						askForAdminReview: "Administrative",
						askForIntervision: "En intervision",
						askForSupervision: "En supervision",
						label: "Signaler un incident",
						person: "Personne impliquée",
						summary: {
							askForAdminReview: "Administrative",
							askForIntervision: "En intervision",
							askForSupervision: "En supervision",
							label: "Demander une revue",
						},
					},
					label: "Type de changement ou d'incident",
					other: {
						label: "Commentaires",
					},
				},
			},
			sessionId: "ID de la session",
			status: {
				canceled: "Session annulée par le demandeur ou bénéficiaire",
				completed: "Session complétée",
				deleted: "Session à supprimer",
				label: "Status final",
				select: "Status",
				warning: "Attention! Changer le status final vous fera perdre l'incidents que vous avez déjà signalé.",
				warning_plural: "Attention! Changer le status final vous fera perdre les {{count}} incidents que vous avez déjà signalés.",
			},
			type: {
				email: "Signer par email",
				label: "Type de validation",
				none: "Pas de signature",
				numeric: "Signature numérique",
				picture: "Signature par photo",
				select: "Type de validation désiré",
			},
			validatedDuration: "Durée validée",
			validation: "Validation de la session",
		},
		"validation-card-picture": "Carte de validation",
	},
};
