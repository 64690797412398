import {Account} from "../../../@types/accounts";
import {ProvidedServiceType} from "../../../@types/activities/mandate";
import {CommunicationType} from "../../../@types/activities/session";
import {InterpretersGroup} from "../../../@types/identities/interpreters-group";
import {PersonListedFields} from "../../../@types/identities/person";
import {Receiver} from "../../../@types/identities/receiver";
import {AvailableSessionLanguagesCommunication} from "../../../@types/language-translation";
import {RequesterSetting, SessionCancelAndRescheduleSetting, Setting} from "../../../@types/settings";
import {updateSettingAPI} from "../../../utils/development/fake/settings/api";
import {api} from "../../../utils/network/api";
import {ApiResponse} from "../../../utils/network/api-response";
import {SessionLanguagesKey} from "../../../utils/sessions/languages";
import {SessionCreationResponse} from "../../@types/session";
import {transformLanguageISOCodeBackToFront} from "../../utils/transform";
import {transformReceiverFormBackToFront, transformReceiverListBackendToFrontend} from "../../utils/transform-receiver";

export type UserSupportGetRequests = {
	GET: {
		pathParams: {
			orgId: string,
		},
	},
};
export type SessionCreationSettingsRequests = {
	GET: {},
};

export const getClientSettings = (accountId: Account["accountId"]): Promise<Setting[]> =>
	Promise.all([
		api("interpreterSupportMailGet", "GET", {}),
		api("sessionCreationSettings", "GET", {}),
	]).then((result) => {
		const {emails, phones} = (result[0] as ApiResponse<{emails: string[], phones: string[]}>).data;
		const {
			availableLanguages: availableLanguagesData,
			termsConditionsURL,
			setupStep: {
				availableServices: {
					isCourseManagementEnabled,
					isInterculturalMediationEnabled,
				},
				minimumDuration,
			},
			detailsStep: {
				paymentAuthority,
				sessionCategory,
				medicalProfessional,
				otherProfessional,
				receiver,
				sessionCancellationReasons,
				sessionRescheduleReasons,
				cancellationDefaultMandatory,
				cancellationDefaultInput,
				cancellationDropDownInput,
				cancellationDropDownMandatory,
				rescheduleDefaultInput,
				rescheduleDropDownInput,
				rescheduleDropDownMandatory,
				rescheduleDefaultMandatory,
				cancellationBillingEnable,
				rescheduleBillingEnable,
				isBillingAddressEditable,
				isUsingTocco,
			},
			interpreterFilters: {
				bhaashaInterpreterGroups,
				isAllBhaashaInterpreterGroupSelected,
				originMandatory,
				origins,
				genderMandatory,
			},
		} = (result[1] as SessionCreationResponse).data;

		const availableServices: ProvidedServiceType[] = ["standard"];
		if (isCourseManagementEnabled) {
			availableServices.push("education");
		}
		if (isInterculturalMediationEnabled) {
			availableServices.push("mediation");
		}
		const availableLanguagesCommunication = {} as AvailableSessionLanguagesCommunication;
		Object.keys(availableLanguagesData).forEach((lang) => {
			const fromLanguage = transformLanguageISOCodeBackToFront(lang);
			const languageMappedObject = new Map<SessionLanguagesKey, Set<CommunicationType>>();
			Object.keys(availableLanguagesData[lang]).forEach((key) => {
				const toLanguage = transformLanguageISOCodeBackToFront(key);
				availableLanguagesCommunication[fromLanguage] =
					languageMappedObject.set(toLanguage, new Set(availableLanguagesData[lang][key]));
			});
		});
		const interpretersGroups: InterpretersGroup[] = bhaashaInterpreterGroups.map((group, i) => ({
			id: group.id.toString(),
			name: group.name,
			default: isAllBhaashaInterpreterGroupSelected || i === 0,
		}));
		const settings: Setting[] = [
			{
				context: "organization/all",
				key: "support/infos",
				owner: accountId,
				settingId: "2",
				value: {
					email: emails[1] ?? emails[0],
					phone: phones[1] ?? phones[0],
				},
			}, {
				settingId: "requesterRequesterEditableFields",
				owner: accountId,
				key: "identity/editable-fields",
				context: "requester/requester",
				value: {
					type: [false, true, ["person"]],
					gender: [true, false, null],
					fullName: [
						true, true, {
							firstName: [true, true, null],
							lastName: [true, true, null],
						},
					],
					email: [true, true, null],
					phone: [true, true, null],
				},
			}, {
				settingId: "requesterPersonEditableFields",
				owner: accountId,
				key: "identity/editable-fields",
				context: "requester/person",
				value: {
					type: [false, true, ["person"]],
					gender: [true, false, null],
					fullName: [
						true, true, {
							firstName: [true, true, null],
							lastName: [true, true, null],
						},
					],
					email: [true, false, null],
					phone: [true, false, null],
				},
			}, {
				settingId: "requesterMedicalProfessionalEditableFields",
				owner: accountId,
				key: "identity/editable-fields",
				context: "requester/medicalProfessional",
				value: {
					type: [false, true, ["medicalProfessional"]],
					gender: [true, false, null],
					fullName: [
						true, true, {
							firstName: [true, true, null],
							lastName: [true, true, null],
						},
					],
					email: [
						true,
						medicalProfessional?.isEmailRequired || false,
						null,
					],
					phone: [true, false, null],
					function: [true, false, null],
					directPhone: [
						true,
						medicalProfessional?.isMobileRequired || false,
						null,
					],
					institution: [
						true,
						!!medicalProfessional?.isInstitutionRequired,
						null,
					],
				},
			}, {
				settingId: "requesterOtherProfessionalEditableFields",
				owner: accountId,
				key: "identity/editable-fields",
				context: "requester/otherProfessional",
				value: {
					type: [false, true, ["otherProfessional"]],
					gender: [true, false, null],
					fullName: [
						true, true, {
							firstName: [true, true, null],
							lastName: [true, true, null],
						},
					],
					email: [true, false, null],
					phone: [true, false, null],
					function: [true, false, null],
					directPhone: [
						true,
						!!otherProfessional?.isMobileRequired,
						null,
					],
				},
			}, {
				settingId: "requesterInterpreterEditableFields",
				owner: accountId,
				key: "identity/editable-fields",
				context: "requester/interpreter",
				value: {
					type: [false, true, ["interpreter"]],
					gender: [false, false, null],
					fullName: [
						false, true, {
							firstName: [false, true, null],
							lastName: [false, true, null],
						},
					],
					dateOfBirth: [false, true, null],
					email: [false, true, null],
					phone: [false, true, null],
					origin: [false, true, null],
				},
			}, {
				settingId: "requesterRequesterListedFields",
				owner: accountId,
				key: "person/listed-fields",
				context: "requester/requester",
				value: new Set(["firstName", "lastName", "gender", "email", "phone"]),
			}, {
				settingId: "requesterPersonListedFields",
				owner: accountId,
				key: "person/listed-fields",
				context: "requester/person",
				value: new Set(["firstName", "lastName", "gender", "email", "phone"]),
			}, {
				settingId: "requesterInterpreterListedFields",
				owner: accountId,
				key: "person/listed-fields",
				context: "requester/interpreter",
				value: new Set(["firstName", "lastName", "gender", "email", "phone"]),
			}, {
				settingId: "requesterMedicalProfessionalListedFields",
				owner: accountId,
				key: "person/listed-fields",
				context: "requester/medicalProfessional",
				value: new Set(["firstName", "lastName", "gender", "email", "phone", "directPhone", "function", "institution"]),
			}, {
				settingId: "requesterOtherProfessionalListedFields",
				owner: accountId,
				key: "person/listed-fields",
				context: "requester/otherProfessional",
				value: new Set(["firstName", "lastName", "gender", "email", "phone", "directPhone", "function"]),
			}, {
				context: "organization/all",
				key: "session/communication",
				settingId: "3",
				owner: accountId,
				value: {
					inPerson: true,
					phone: true,
					video: true,
				},
			}, {
				context: "organization/all",
				key: "session/creation",
				owner: accountId,
				settingId: "4",
				value: {
					availableLanguagesCommunication,
					detailsStep: {
						billingAddress: {
							editRights: {
								canAddNew: isBillingAddressEditable,
								canEdit: isBillingAddressEditable,
								canDelete: isBillingAddressEditable,
							},
						},
						toXInterpreter: {
							mandatory: true,
						},
						requester: {
							mandatory: true,
						},
						subject: {
							mandatory: true,
						},
						expectation: {
							mandatory: true,
						},
						goal: {
							mandatory: true,
						},
						involvedCorporations: {
							mandatory: true,
						},
						involvedPeople: {
							mandatory: true,
						},
						reason: {
							mandatory: true,
						},
						internalComment: {
							mandatory: false,
						},
						interpreterComment: {
							mandatory: false,
						},
						receiver: {
							label: receiver?.patientLabel?.label,
							editRights: {
								canAddNew: true,
								canEdit: false,
								canDelete: false,
							},
							mandatory: receiver.patientLabel?.isRequired === "true",
						},
						medicalProfessional: {
							label: medicalProfessional?.label,
							mandatory: medicalProfessional?.isRequired,
							editRights: {
								canAddNew: true,
								canEdit: false,
								canDelete: false,
							},
						},
						otherProfessional: otherProfessional?.isShow
							? {
								label: otherProfessional?.label,
								mandatory: otherProfessional?.isRequired,
								editRights: {
									canAddNew: true,
									canEdit: false,
									canDelete: false,
								},
							}
							: undefined,
						paymentAuthority: paymentAuthority?.length > 0
							? {
								label: paymentAuthority[0].name,
								mandatory: paymentAuthority[0].isRequired,
								editRights: {
									canAddNew: paymentAuthority[0].isCreationAllowedForUser,
									canEdit: paymentAuthority[0].isEditAllowedForUser,
									canDelete: paymentAuthority[0].isDeleteAllowedForUser,
								},
							}
							: undefined,
						sessionCategory: sessionCategory?.length > 0
							? {
								label: sessionCategory[0].name,
								mandatory: sessionCategory[0].isRequired,
							}
							: undefined,
					},
					interpreterFilters: {
						interpretersGroups,
						genderMandatory,
						originMandatory,
						origins,
					},
					setupStep: {
						availableSessionTypes: ["interpreterMandate"],
						availableServices,
						minimumDuration,
					},
					termsConditionsURL,
				},
			},
		];
		if (receiver.isPatientOriginRequired) {
			receiver.formData.push({
				isRequired: receiver.isPatientOriginMandatory,
				fieldName: "origin",
				isSelected: true,
				labelName: null,
				isShow: false,
			});
		}
		const authorizedFields = transformReceiverFormBackToFront(receiver.formData, "requester");
		if (Object.keys(authorizedFields).length > 0) {
			settings.push({
				settingId: "requesterReceiverEditableFields",
				owner: accountId,
				key: "identity/editable-fields",
				context: "requester/receiver",
				value: {
					type: [false, true, ["receiver"]],
					...authorizedFields,
				},
			});
		}
		const listedFields = transformReceiverListBackendToFrontend(receiver.formData);

		if (listedFields.length > 0) {
			const receiverList: PersonListedFields<Receiver> = new Set(listedFields);
			settings.push({
				settingId: "requesterReceiverListedFields",
				owner: accountId,
				key: "person/listed-fields",
				context: "requester/receiver",
				value: receiverList,
			});
		}

		const sessionCancelOrReschedule: SessionCancelAndRescheduleSetting = {
			settingId: "sessionCancelOrReschedule",
			owner: accountId,
			key: "session/cancel-or-reschedule",
			context: "organization/requesters",
			value: {
				cancel: {
					dropdown: cancellationDropDownInput && sessionCancellationReasons?.length > 0
						? {
							values: sessionCancellationReasons?.map(({id, reason}) => ({id: id.toString(), reason})),
							mandatory: cancellationDropDownMandatory,
						}
						: null,
					textField: cancellationDefaultInput
						? {mandatory: cancellationDefaultMandatory}
						: null,
					displayCancelDueToInterpreter: cancellationBillingEnable ?? false,
				},
				reschedule: {
					dropdown: rescheduleDropDownInput && sessionRescheduleReasons?.length > 0
						? {
							values: sessionRescheduleReasons?.map(reason => ({id: reason.id.toString(), reason: reason.reason})),
							mandatory: rescheduleDropDownMandatory,
						}
						: null,
					textField: rescheduleDefaultInput
						? {mandatory: rescheduleDefaultMandatory}
						: null,
					displayCancelDueToInterpreter: rescheduleBillingEnable ?? false,
				},
			},
		};
		settings.push(sessionCancelOrReschedule);

		if (isUsingTocco) {
			settings.push({
				settingId: "tocco",
				owner: accountId,
				key: "tocco",
				context: "organization/requesters",
				value: {
					usingTocco: isUsingTocco,
				},
			});
		}

		return settings;
	});

export const updateClientSetting = (updated: RequesterSetting): Promise<void> =>
	updateSettingAPI(updated);
