import {languages as enUSLanguages} from "../en-US/languages";

export const languages: typeof enUSLanguages = {
	akuapem: "Akuapem",
	albanian: "Albanisch",
	amharic: "Amharisch",
	arab: "Arabisch",
	"arab-maghreb": "Arabisch (Maghrebinisch)",
	"arabic-classical": "Arabisch (Klassisch)",
	"arabic-levantine": "Arabisch (Levantinisch)",
	aramaic: "Aramäisch",
	armenian: "Armenisch",
	asante: "Asante",
	azerbaijani: "Aserbaidschanisch",
	azeri: "Azeri",
	bambara: "Bambara",
	basque: "Baskisch",
	bengali: "Bengali",
	berber: "Berber",
	bilen: "Bilen",
	bosnian: "Bosnisch",
	bulgarian: "Bulgarisch",
	chechen: "Tschetschenisch",
	"chinese-cantonese": "Chinesisch Kantonesisch",
	"chinese-mandarin": "Chinesisch Mandarin",
	creole: "Kreolisch",
	croatian: "Kroatisch",
	czech: "Tschechisch",
	dari: "Dari",
	diola: "Diola",
	dutch: "Niederländisch",
	dyula: "Dioula",
	english: "Englisch",
	esan: "Esan",
	ewe: "Ewe",
	fante: "Fante",
	farsi: "Farsi (Persisch)",
	french: "Französisch",
	fula: "Ful",
	fulfude: "Foulfouldé",
	ga: "Ga",
	gagauz: "Gagausisch",
	georgian: "Georgisch",
	german: "Deutsch",
	greek: "Griechisch",
	guarani: "Guarani",
	gujarati: "Gujarati",
	hausa: "Hausa",
	hazaragi: "Hazaragi",
	hebrew: "Hebräisch",
	hindi: "Hindi",
	hungarian: "Ungarisch",
	igbo: "Igbo",
	italian: "Italienisch",
	japanese: "Japanisch",
	kazakh: "Kasachisch",
	khmer: "Khmer (Kambodschanisch)",
	kikongo: "Kikongo",
	kinyarwanda: "Kinyarwanda",
	kirgisisch: "Kirgisisch",
	kirundi: "Kirundi",
	kissi: "Kissi",
	kiswahili: "Kiswahili",
	korean: "Koreanisch",
	"kurdish-badini": "Kurdisch Badini",
	"kurdish-kurmanji": "Kurdisch Kurmanci",
	"kurdish-kurmanji-(syria)": "Kurdisch Kurmanci (Syrien)",
	"kurdish-kurmanji-(turkey)": "Kurdisch Kurmanci (Türkei)",
	"kurdish-sorani": "Kurdisch Sorani",
	"kurdish-zazaki": "Kurdisch Zazaki",
	kutchi: "Katchi",
	lao: "Laotisch",
	latvian: "Lettisch",
	lingala: "Lingála",
	macedonian: "Mazedonisch",
	malagasy: "Madagassisch",
	malayalam: "Malayalam",
	malaysian: "Malaiisch",
	malinke: "Malinka",
	mandingo: "Mandinga",
	mandinka: "Mandinka",
	masalit: "Masalit",
	mbaye: "Mbaye",
	mina: "Mina",
	mongolian: "Mongolisch",
	nepali: "Nepalesisch",
	ngambaye: "Ngambaye",
	nyabode: "Nyabode",
	oromo: "Oromo",
	pashto: "Pashto",
	persan: "Persisch",
	"pidgin-english": "Pidgin Englisch",
	polish: "Polnisch",
	portugese: "Portugiesisch",
	"portugese-brasil": "Portugiesisch (Brasilien)",
	"portugese-portugal": "Portugiesisch (Portugal)",
	pulaar: "Pulaar",
	punjabi: "Panjabi",
	romani: "Romanes",
	romanian: "Rumänisch",
	russian: "Russisch",
	saho: "Saho",
	serbian: "Serbisch",
	sidama: "Sidama",
	sinhalese: "Singalesisch",
	slovakian: "Slowakisch",
	slovenian: "Slowenisch",
	somali: "Somali",
	soninke: "Soninke",
	soussou: "Soussou",
	spanish: "Spanisch",
	swahili: "Swahili",
	"swiss-german": "Schweizerdeutsch",
	tagalog: "Tagalog (Philippinisch)",
	tajik: "Tadschikisch",
	tamil: "Tamil",
	thai: "Thai",
	tibetan: "Tibetisch",
	tigre: "Tigre",
	tigrinya: "Tigrinya",
	tshiluba: "Tshiluba",
	turkish: "Türkisch",
	turkmen: "Turkmenisch",
	twi: "Twi",
	ukrainian: "Ukrainisch",
	"ukrainian-russian": "Ukrainisch (Russisch)",
	urdu: "Urdu",
	uyghur: "Uigur",
	uzbek: "Usbekisch",
	vietnamien: "Vietnamesisch",
	wolof: "Wolof",
	yoruba: "Yoruba",
};
